import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PhoneField from "../../components/Fields/CTA/PhoneField";
import TextInput from "../../components/Fields/CTA/TextInput";
import { strings } from "../../utils/localization";
import { validatePhoneNumber } from "../../utils/validation";
import AcceptTermsFooter from "../components/Footer/AcceptTermsFooter";

const Container = styled.div`
  width: 100%;
  background: #fff;
  height: 100%;
  display: flex;
  border-radius: 8px;
  position: relative;
  flex-direction: column;
  justify-content: center;

  #name-wrapper {
    position: inherit;
  }

  #submit-button {
    top: 26%;
  }

  #submit-text {
    top: 50%;
    transform: translateY(-50%);
  }

  #small-submit-button {
    position: absolute;
    top: 25%;
  }

  #small-submit-icon {
    position: absolute;
    top: 31%;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;

  .text-me,
  input {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto !important;
    background: #fff !important;
    border: 0;
    color: #222222;
    padding-left: ${(props) => (props.hideFlag ? "10px" : "65px !important")};
  }

  #name {
    padding-left: 15px !important;
  }

  .react-tel-input {
    margin-left: 5px !important;
  }

  .flag-dropdown {
    margin-left: 5px !important;
    padding: 0px !important;
    margin-top: 18px !important;
  }

  #submit-button {
  }

  #name-wrapper #submit-text {
    //right: 5px;
  }

  #name {
    padding-left: 10px !important;
  }
`;

const Note = styled.p`
  font-size: 8px;
  opacity: 0.5;
  color: black;
  text-align: center;
  margin: 0;
  line-height: 100%;
`;

const CtaForm = ({
  phoneNumber,
  setPhoneNumber,
  name,
  setName,
  onSubmit,
  primaryColor,
  width,
  organizationCountryCode = "us",
  textColor,
  termsLink = "http://matador.ai/?utm_source=matador%20connect%20widget&utm_medium=link&utm_campaign=widget%20referral",
}) => {
  const [step, setStep] = useState(0);
  const [fontSize, setFontSize] = useState();
  const [fieldTouched, setFieldTouched] = useState(false);

  useEffect(() => {
    handleFontSize();
  }, []);

  const getValidation = (value) => {
    const phone = value || phoneNumber;
    return validatePhoneNumber(phone);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (step === 0) {
        if (getValidation()) {
          setStep(1);
        }
      } else {
        onSubmit(0);
      }
    }
  };

  const onSubmitPhone = () => {
    if (getValidation()) {
      setStep(1);
    }
  };

  const getPhoneNumberFromClipboard = (event) => {
    let pasteValue = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    pasteValue = pasteValue.replace(/-|\s/g, "");
    pasteValue = pasteValue.replace("(", "");
    pasteValue = pasteValue.replace(")", "");
    pasteValue = pasteValue.replace("tel:", "");
    pasteValue = pasteValue.replace("+", "");

    if (pasteValue.length === 10) {
      pasteValue = `1${pasteValue}`;
    }
    if (pasteValue.length === 11) {
      pasteValue = `1${pasteValue && pasteValue.slice(1)}`;
    }

    getValidation(pasteValue);
    setPhoneNumber(pasteValue);
  };

  const handleFontSize = () => {
    const parentEl = document.getElementById("cta-view-content");
    if (parentEl?.offsetWidth < 200) {
      return setFontSize("10px");
    }
    return setFontSize("14px");
  };

  const getValue = () => {
    if (
      organizationCountryCode?.toLowerCase() === "au" &&
      !phoneNumber &&
      !fieldTouched
    ) {
      return "+04";
    }
    return phoneNumber;
  };

  const onChangeHandler = (value) => {
    setFieldTouched(true);
    if (
      organizationCountryCode?.toLowerCase() === "au" &&
      !value &&
      !fieldTouched
    ) {
      return setPhoneNumber(`+04${value}`);
    }
    return setPhoneNumber(value);
  };

  const getField = () => {
    return step === 0 ? (
      <PhoneField
        theme="default"
        initialCountry={organizationCountryCode?.toLocaleLowerCase() || "us"}
        value={getValue()}
        onChange={onChangeHandler}
        enableSubmit={getValidation()}
        onSubmit={onSubmitPhone}
        onKeyDown={handleKeyDown}
        primaryColor={primaryColor}
        hideFlag={width === "S"}
        useIcon={width === "S"}
        getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
        fontSize={fontSize}
        masks={{ au: ".. .... ...." }}
        disableCountryCode={organizationCountryCode?.toLowerCase() === "au"}
      />
    ) : (
      <TextInput
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder={strings.WHATS_YOUR_NAME}
        enableSubmit={getValidation()}
        onSubmit={onSubmit}
        theme="default"
        onKeyDown={handleKeyDown}
        primaryColor={primaryColor}
        useIcon={width === "S"}
        fontSize={fontSize}
      />
    );
  };

  return (
    <Container>
      <ContentWrapper hideFlag={width === "S"}>{getField()}</ContentWrapper>
      {step === 1 && (
        <div style={{ marginBottom: -5, paddingLeft: 5 }}>
          <AcceptTermsFooter termsLink={termsLink} margin={0} type="cta" />
        </div>
      )}
    </Container>
  );
};

export default CtaForm;
