import React from "react";
import "../styles/LiveChatStyle.css";
import {
  SVGSuccess,
  SVGChatBack,
  SVGChatClose,
  SVGSendIcon,
} from "./Icons/Icons";
import PhoneNumberField from "./Fields/PhoneNumberField";
import { strings } from "../utils/localization";
import MetaButton from "./Buttons/SubmitButton";
import AdjustFontSize from "../utils/AdjustFontSize";
import AcceptTermsFooter from "../v2/components/Footer/AcceptTermsFooter";
import MatadorInput from "../v2/components/Input/MatadorInput";
import styled from "styled-components";

const Button = styled.button`
  padding: 12px 25px;
  background-color: ${({ primaryColor }) => primaryColor};
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 16px;
  margin-top: 10px;

  &:disabled {
    background-color: #999;
    cursor: not-allowed;
  }
`;

const ChatBoxPreviewLight = (props) => {
  const _ = AdjustFontSize({ className: ".matadot-live-chat-123789-h2" });
  const [formSubmitted, setFormSubmitted] = React.useState(false);

  const checkNameValidation = () => {
    const { isCustomerNameRequired, firstAndLastNameSeparately } = chatData;
    const { firstName, lastName } = props;

    let valid = true;

    if (isCustomerNameRequired) {
      if (firstAndLastNameSeparately) {
        valid = firstName?.trim() !== "" && lastName?.trim() !== "";
      } else {
        valid = firstName?.trim() !== "";
      }
    }
    return valid;
  };

  const handleSubmit = () => {
    const { submit } = props;
    setFormSubmitted(true);
    const nameValid = checkNameValidation();
    if (!nameValid) {
      return;
    }
    submit({ viewIndex: 4, send: true });
  };

  const renderName = () => {
    const { chatData, setFirstName, firstName, lastName, setLastName } = props;
    const { isCustomerNameRequired } = chatData;

    let color = chatData && chatData.color ? chatData.color : "white";

    if (chatData.firstAndLastNameSeparately) {
      return (
        <div style={{ paddingTop: 15 }}>
          <MatadorInput
            placeholder={strings.FIRST_NAME}
            color={color}
            value={firstName}
            onChange={(value) => setFirstName(value)}
            required
            enableValidation={isCustomerNameRequired}
            formSubmitted={formSubmitted}
          />
          {!isCustomerNameRequired ? <br /> : <div style={{ height: 8 }} />}
          <MatadorInput
            placeholder={strings.LAST_NAME}
            color={color}
            value={lastName}
            onChange={(value) => setLastName(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            required
            enableValidation={isCustomerNameRequired}
            formSubmitted={formSubmitted}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              primaryColor={chatData.color}
              onClick={handleSubmit}
            >
              {strings.SEND}
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="matador-live-chat-123789-input-with-button"
          style={{ paddingTop: 20 }}
        >
          <MatadorInput
            placeholder={strings.WHATS_YOUR_NAME}
            color={color}
            value={firstName}
            onChange={(value) => setFirstName(value)}
            required
            enableValidation={isCustomerNameRequired}
            formSubmitted={formSubmitted}
          />
          <MetaButton
            style={{ backgroundColor: color }}
            onClick={handleSubmit}
            sendIcon={<SVGSendIcon />}
          />
        </div>
      );
    }
  };

  const renderChatBoxOne = () => {
    const { chatData, setQuestionId, handleSelectQuestion, listQuestions } =
      props;

    let color = chatData && chatData.color ? chatData.color : "white";
    const textColor =
      chatData && chatData.headerTextColor ? chatData.headerTextColor : "#fff";

    return (
      <div className="matadot-live-chat-123789-chatbox-middle">
        <div className="matadot-live-chat-123789-questons-scrollbars">
          {listQuestions &&
            listQuestions.map((obj, index) => {
              let question =
                obj._question &&
                obj._question.question &&
                obj._question.question[strings.getLanguage()]
                  ? obj._question.question[strings.getLanguage()]
                  : obj._question.question[
                      Object.keys(obj._question.question)[0]
                    ]
                  ? obj._question.question[
                      Object.keys(obj._question.question)[0]
                    ]
                  : "";
              return (
                <div
                  key={index}
                  className={"matadot-live-chat-123789-question-button"}
                  style={{ borderColor: color, color: textColor }}
                  onClick={() => {
                    setQuestionId(obj._question_id);
                    handleSelectQuestion(question);
                  }}
                >
                  {question}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderChatBoxTwoAndTree = () => {
    let {
      chatData,
      phoneNumber,
      submit,
      viewIndex,
      setPhoneNumber,
      defaultCountryFlag,
      isValidPhoneNumber,
      getPhoneNumberFromClipboard,
    } = props;

    let first_message =
      chatData && chatData.texts.first_message
        ? chatData.texts.first_message
        : "";
    let second_message =
      chatData && chatData.texts.second_message
        ? chatData.texts.second_message
        : "";
    let color = chatData && chatData.color ? chatData.color : "white";
    let termsLink = chatData.termsLink
      ? chatData.termsLink
      : "http://matador.ai/?utm_source=matador%20connect%20widget&utm_medium=link&utm_campaign=widget%20referral";

    return (
      <>
        {viewIndex !== 4 && viewIndex !== 3 && (
          <div className="matadot-live-chat-123789-chatbox-middle-light-theme">
            <div className="matadot-live-chat-123789-chatbox-middle-light-theme-header">
              {first_message}
            </div>
          </div>
        )}
        {viewIndex !== 4 && viewIndex === 3 && (
          <div className="matadot-live-chat-123789-chatbox-middle-light-theme">
            <div className="matadot-live-chat-123789-chatbox-middle-light-theme-header">
              {second_message}
            </div>
          </div>
        )}
        {viewIndex !== 3 && viewIndex !== 4 && (
          <div className="matadot-live-chat-123789-write-text-message">
            <div className="matador-live-chat-123789-input-with-button">
              <div className="matador-live-chat-123789-input-with-icon">
                <PhoneNumberField
                  value={phoneNumber}
                  onChange={(value) => setPhoneNumber(value)}
                  defaultCountryFlag={defaultCountryFlag}
                  getPhoneNumberFromClipboard={getPhoneNumberFromClipboard}
                />
              </div>
              <MetaButton
                style={{ backgroundColor: color }}
                onClick={() => submit({ viewIndex: 3 })}
                sendIcon={<SVGSendIcon />}
                // Temp validation for US and CA
                disabled={!isValidPhoneNumber}
              />
            </div>
            <AcceptTermsFooter
              color={chatData.termsLink}
              termsLink={chatData.termsLink}
            />
          </div>
        )}
        {viewIndex === 3 && viewIndex !== 4 && (
          <div className="matadot-live-chat-123789-write-text-message">
            {renderName()}
            <div className="matadot-live-chat-123789-chatbox-bottom">
              <AcceptTermsFooter
                color={chatData.termsLink}
                termsLink={chatData.termsLink}
              />
            </div>
          </div>
        )}
        {viewIndex === 4 && (
          <div className="matadot-live-chat-123789-write-text-message">
            <div className="matadot-live-chat-123789-last-view">
              <SVGSuccess height="51" width="51" />
              <div className="matadot-live-chat-123789-last-view-text">
                <h2>Thanks!</h2>
                <h3>We will contact you soon!</h3>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const { viewIndex, onBackClick, chatData, backView } = props;
  const welcome_title =
    chatData && chatData.texts ? chatData.texts.welcome_title : "";
  let style =
    viewIndex === 1
      ? {}
      : {
          backgroundColor: `background: linear-gradient(0deg, ${chatData?.color} 50%, ##F5F5F5 50%);`,
        };

  if (!chatData) {
    return null;
  }

  return (
    <div
      className={
        "matadot-live-chat-123789-light-chatbox-preview" +
        (chatData.theme === "light" &&
          "   matadot-live-chat-123789-light-chatbox-preview-border")
      }
    >
      <div
        className={
          `matadot-live-chat-123789-chatbox matadot-live-chat-123789-chatbox-current-view-${viewIndex}` +
          (backView && backView ? backView : "")
        }
      >
        {viewIndex === 1 && (
          <div style={style} className="matadot-live-chat-123789-chatbox-top">
            <div className="matadot-live-chat-123789-h2-with-icon">
              {viewIndex === 2 && (
                <div
                  className="matadot-live-chat-123789-back-view"
                  onClick={() => {
                    onBackClick();
                  }}
                >
                  <SVGChatBack />
                </div>
              )}
              <h2
                className="matadot-live-chat-123789-h2"
                style={
                  viewIndex === 1
                    ? { color: chatData.headerTextColor }
                    : { color: "#fff" }
                }
              >
                {welcome_title}
              </h2>
              {viewIndex !== 1 ? (
                <div className="matador-live-chat-123789-close-btn">
                  <SVGChatClose
                    stroke={1.6}
                    color={viewIndex === 1 ? chatData.headerTextColor : "#fff"}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
        {viewIndex === 1 && renderChatBoxOne()}
        {(viewIndex === 2 || viewIndex === 3 || viewIndex === 4) &&
          renderChatBoxTwoAndTree()}
      </div>
    </div>
  );
};

export default ChatBoxPreviewLight;
