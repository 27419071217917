import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../Header/Header";
import QuestionsList from "./Questions/QuestionsList";
import SendQuestion from "./Questions/SendQuestion";
import useWiz from "./Wiz/useWiz";
import LocationsList from "./Questions/LocationsList";
import useAnalytics from "../../../utils/useAnalytics";
import chatSettingsMainActions from "../../../components/modules/chatSettingsMainActions";
import { useContextData } from "../../contexts/ContextDataProvider";
import useSecondaryColor from "../../utils/useSecondaryColor";

const Container = styled.div`
  background-color: #f6f7fb;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px 10px 20px;
  background-color: #f6f7fb;
  background: linear-gradient(
    to bottom,
    ${(props) => props.backgroundColor} 0%
      ${(props) =>
        props.textPosition === "middle"
          ? "10px #f6f7fb 20px 100%"
          : "65px #f6f7fb 20px 100%"}
  );
  ${(props) =>
    props.textPosition === "middle" &&
    `
      flex: 1;
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;
  `}
`;
const TopContent = styled.div`
  flex: 1;
  overflow-y: auto;
  border-bottom: 1px solid #e2e8f0;

  ${(props) =>
    props.textPosition === "middle" &&
    `
      display: flex;
      flex-direction: column;
      height: 100%;
  `}

  ::-webkit-scrollbar {
    position: relative;
    display: block;
    width: 5px;
    cursor: pointer;
    border-radius: 100px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    margin-left: -10px;
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #00000033;
  }
  @supports (scrollbar-color: #00000033 transparent) {
    scrollbar-color: #00000033 transparent;
    scrollbar-width: thin;
  }
`;

const DefaultView = ({
  chatData,
  onQuestionClick,
  setSelectedQuestion,
  selectedQuestion,
  handleSelectQuestion,
  questions,
  token,
  language,
  questionId,
  setViewIndex,
  viewIndex,
  goBack,
}) => {
  const {
    texts,
    color,
    organizationTimeZone,
    organizationWorkingHours,
    organizationCustomWorkingHours,
  } = chatData || {};
  const {
    getContentType,
    getLocations,
    setActiveLocation,
    wizData,
    activeLocation,
    getLocationsByQuestionId,
    updateActiveToken,
  } = useWiz();
  const { sendCtaInteractionEvent } = useAnalytics(chatData?.GATrackingNumber);
  const { stories, setStories } = useContextData();
  const { secondaryColor } = useSecondaryColor(token);

  useEffect(() => {
    loadStories();
  }, []);

  const onLocationClick = (location) => {
    setActiveLocation(location);
    sendCtaInteractionEvent({ widget: "Connect" });
  };

  const onLocationClickHandler = (location) => {
    handleQuestionLocationSelection(location);
    setViewIndex(2);
    updateActiveToken(location._token);
    sendCtaInteractionEvent({ widget: "Connect" });
  };

  const renderSendQuestion = () => {
    const type = getContentType();

    if (
      (type === "Questions -> Locations" && questionId && !activeLocation) ||
      token === "7e9c15d77d70128c27f95d8a2e841fe9bfd75cf0" ||
      token === "aaf6515c2f789f408bda9f70b4e6c00f4ed12e08"
    ) {
      return null;
    }

    return (
      <SendQuestion
        setSelectedQuestion={setSelectedQuestion}
        handleSelectQuestion={handleSelectQuestion}
        selectedQuestion={selectedQuestion}
        color={color}
        viewIndex={viewIndex}
        arrowColor={secondaryColor}
        enabled={!chatData.removeQuestionInput}
      />
    );
  };

  const loadStories = async () => {
    let stories = await chatSettingsMainActions.getStories(
      { page: 1, perPage: 10 },
      token
    );

    if (stories.success) {
      setStories(stories.data.stories);
    }
  };

  const handleQuestionLocationSelection = (location) => {
    const items = wizData?.steps[0]?.questionsAndLocations;

    for (let i = 0; i < items?.length; i++) {
      const locations = items[i].locations;

      for (let j = 0; j < locations.length; j++) {
        if (locations[j]._token === location?._token) {
          const question = locations[j].question;

          if (question && question?.question[language] === selectedQuestion) {
            const item = {
              ...question,
              question: question?.question[language],
              answer: question?.answer?.answer?.[language],
            };
            return onQuestionClick(item);
          }
        }
      }
    }
  };

  const renderContent = () => {
    const type = getContentType();

    if (wizData) {
      let locations = getLocations();

      if (
        (type === "Locations" || type === "Questions -> Locations") &&
        locations?.length &&
        !activeLocation
      ) {
        return (
          <LocationsList
            locations={locations}
            onLocationClick={onLocationClick}
            language={language}
            isBold={chatData.bold}
          />
        );
      }

      if (type === "Questions -> Locations" && questionId && !activeLocation) {
        locations = getLocationsByQuestionId(questionId);

        return (
          <LocationsList
            locations={locations}
            onLocationClick={onLocationClickHandler}
            language={language}
            goBack={goBack}
            isBold={chatData.bold}
          />
        );
      }

      if (type === "Questions -> Locations") {
        return (
          <QuestionsList
            questions={questions}
            onQuestionClick={onQuestionClick}
            token={token}
            backgroundColor={color}
            isBold={chatData.bold}
          />
        );
      }
    }

    return (
      <QuestionsList
        questions={questions}
        onQuestionClick={onQuestionClick}
        token={token}
        backgroundColor={color}
        goBack={type === "Locations" && goBack}
        isBold={chatData.bold}
      />
    );
  };

  return (
    <Container>
      <Content>
        <TopContent textPosition={chatData.textPosition}>
          <Header
            backgroundColor={color}
            title={texts ? texts.welcome_title : ""}
            token={token}
            language={language}
            organizationTimeZone={organizationTimeZone}
            organizationWorkingHours={organizationWorkingHours}
            organizationCustomWorkingHours={organizationCustomWorkingHours}
            stories={stories}
          />

          <MainContent
            backgroundColor={stories?.length > 0 ? "#f6f7fb" : color}
            textPosition={chatData.textPosition}
          >
            {renderContent()}
          </MainContent>
        </TopContent>
        {renderSendQuestion()}
      </Content>
    </Container>
  );
};

export default DefaultView;
