import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { strings } from "../../../utils/localization";
import Stories from "../Story/Stories";

import { wizActiveStep } from "../../../store/reducers/wizModule";
import StoreStatus from "../Chat/Store/StoreStatus";
import { ReactComponent as CloseIcon } from "../../../assets/images/common/closeIcon.svg";
import useSecondaryColor from "../../utils/useSecondaryColor";

const Container = styled.div`
  padding: 24px 16px 10px 16px;
  background: ${(props) => props.backgroundColor};
  flex: 0 1 auto;
  //position: relative;
`;

const StartConversation = styled.span`
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
  line-height: 24px;
  font-weight: 400;
`;

const Label = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  text-transform: capitalize;
  margin-top: 0px;
  margin-bottom: 8px;
  color: ${(props) => props.color};
  letter-spacing: -0.24px;
  line-height: 24px;
`;

const StoriesContainer = styled.div`
  margin: 0px -16px -16px -16px;
  padding-top: 20px;
`;

const CloseContainer = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  cursor: pointer;
  padding: 20px;
`;

const Header = ({
  title,
  backgroundColor,
  token,
  language = "en",
  organizationTimeZone,
  organizationWorkingHours,
  organizationCustomWorkingHours,
  stories,
}) => {
  const wizActiveStepData = useSelector(wizActiveStep);
  const { secondaryColor } = useSecondaryColor(token);

  const getDescriptionText = () => {
    return (
      wizActiveStepData?.description?.[language] ||
      strings.TEXT_CHAT_HELP_START_CONVERSATION
    );
  };

  const getTitle = () => {
    return (
      (wizActiveStepData?.title && wizActiveStepData?.title[language]) || title
    );
  };

  const closeMatdorConnect = () => {
    window.top.postMessage("close_matador_connect", "*");
  };

  const closeIcon = () => {
    return (
      <CloseContainer id="close-container" onClick={closeMatdorConnect}>
        <CloseIcon height={12} width={12} stroke={secondaryColor} />
      </CloseContainer>
    );
  };

  return (
    <Container backgroundColor={backgroundColor}>
      <Label color={secondaryColor}>{getTitle()}</Label>
      <StartConversation color={secondaryColor}>
        {getDescriptionText()}
      </StartConversation>

      {closeIcon()}

      <StoreStatus
        organizationTimeZone={organizationTimeZone}
        organizationWorkingHours={organizationWorkingHours}
        organizationCustomWorkingHours={organizationCustomWorkingHours}
        token={token}
      />

      <StoriesContainer>
        <Stories
          stories={stories}
          backgroundColor={backgroundColor}
          token={token}
        />
      </StoriesContainer>
    </Container>
  );
};

export default Header;
