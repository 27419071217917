import React from "react";
import styled from "styled-components";
import { strings } from "../../../../utils/localization";
import QuestionItem from "./QuestionItem";
import useWiz from "../Wiz/useWiz";
import { ReactComponent as BackIcon } from "../Form/assets/back-icon.svg";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 2px 2px 15px 0px rgba(83, 85, 128, 0.1);
  border-radius: 16px;
  padding: 12px;
  background-color: #fff;
`;

const Item = styled.div`
  cursor: pointer;
  margin-top: 8px;
  width: 100%;

  :nth-child(1) {
    margin-top: 0px;
  }
`;

const BackCointainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
`;

const QuestionsList = ({
  questions,
  defaultLanguage = "en",
  onQuestionClick,
  token,
  goBack,
  isBold,
}) => {
  const { getWizQuestions } = useWiz();

  let language = strings.getLanguage();

  if (language === "es") {
    language = "sp";
  }

  const getData = () => {
    const items = getWizQuestions();
    if (items?.length) {
      return items.map((item) => ({
        id: item.questionId,
        question:
          item.question.question[language] ||
          item.question.question[defaultLanguage],
        answer:
          item.question?.answer?.answer[language] ||
          item.question?.answer?.answer[defaultLanguage],
        type: item.question?.type,
        _token: item._token,
        redirectedURL: item.question.redirectedURL,
        useRedirect: item.question.useRedirect,
      }));
    }

    return questions.map((item) => ({
      id: item._question_id,
      question:
        item._question.question[language] ||
        item._question.question[defaultLanguage],
      answer:
        item._question?.answer?.answer[language] ||
        item._question?.answer?.answer[defaultLanguage],
      type: item._question?.type,
      redirectedURL: item._question.redirectedURL,
      useRedirect: item._question.useRedirect,
    }));
  };

  return (
    <Container>
      {goBack && (
        <BackCointainer onClick={goBack}>
          <BackIcon />
        </BackCointainer>
      )}
      {getData().map((item, index) => (
        <Item key={`${item.question}-${index}`}>
          <div onClick={() => onQuestionClick(item)}>
            <QuestionItem
              token={token}
              question={item.question}
              answer={item.answer}
              index={index}
              isBold={isBold}
            />
          </div>
        </Item>
      ))}
    </Container>
  );
};

export default QuestionsList;
